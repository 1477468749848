<template>
  <div>
   <el-form :model="search">
     <el-row :gutter="20">
       <el-col :span="5">
         <el-form-item label="产品名称">
           <el-input v-model="search.product_name" placeholder="请输入产品名称" />
         </el-form-item>
       </el-col>
      <el-col :span="6">
        <el-form-item label="请选择产品状态">
          <el-select placeholder="请选择产品状态" v-model="search.status">
            <el-option label="正常" :value="0" />
            <el-option label="已下架" :value="1" />
          </el-select>
        </el-form-item>
      </el-col>
       <el-col :span="2">
         <el-form-item>
           <el-button type="primary" @click="onSearchBtn" :icon="Search"
             >搜索</el-button
           >
         </el-form-item>
       </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="onAddMain">
          <el-icon><CirclePlus /></el-icon> &nbsp;添加产品
        </el-button>
      </el-col>
     </el-row>
   </el-form>

    <!-- 表格 -->

    <el-table
      :data="listData"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
     <el-table-column prop="id" label="id" width="150px" />
       <el-table-column label="产品图片" width="180px">
         <template #default="{ row, $index }">
           <el-image
             @click="previewUrl($index)"
             style="width: 50px; height: 50px"
             :preview-teleported="true"
             :src="'http://zct.yslts.com/' + row.img"
             :initial-index="imgIndex"
             fit="cover"
             :preview-src-list="srcList"
           />
         </template>
       </el-table-column>
       <el-table-column prop="product_name" label="产品名称" width="200px" />
       <el-table-column label="产品状态" width="150">
         <template v-slot="{ row }">
           {{ row.status ? '已下架' : '正常' }}
         </template>
       </el-table-column>
       <el-table-column prop="add_time" label="添加时间" width="300px" />
       <el-table-column prop="update_time" label="更新时间" width="300px" />
       <el-table-column label="操作" align="center">
         <template #default="{ row }">
           <el-button type="primary" size="small" @click="onEditBtn(row)"
             >修改</el-button
           >
           <el-popconfirm
             title="是否下架该产品?"
             confirm-button-text="确认"
             cancel-button-text="取消"
             @confirm="deleteBtn(row.id)"
           >
             <template #reference>
               <el-button type="danger" size="small" v-if="row.status !== 1"> 下架 </el-button>
             </template>
           </el-popconfirm>
         </template>
       </el-table-column>
     </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <addmain
      v-model="layerAddEdit"
      :onListData="onListData"
      :productMain="productMain"
    ></addmain>
  </div>
</template>

<script setup>
// articleListAPI
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { productmainListAPI, ProductDelAPI } from '@/api/product'
import addmain from './addEdit.vue'

const layerAddEdit = ref(false)

/**
 *  表单 S
 */
// 表单数据源
const search = ref({
  class_id: '',
  title: '',
  page: 1,
  limit: 10,
  total: 0
})
// 搜索
const onSearchBtn = () => {
  onListData()
}

/**
 *  表单 E
 */

/*
 *  表格： S
 */

// 列表
const listData = ref([])
const onListData = async () => {
  const { data, total } = await productmainListAPI(search.value)
  search.value.total = total
  listData.value = data
}
onListData()

// 新增产品
const onAddMain = () => {
  productMain.value = {}
  layerAddEdit.value = true
}

// 编辑
const productMain = ref({})
const onEditBtn = (row) => {
  productMain.value = row
  layerAddEdit.value = true
}

// 下架
const deleteBtn = async (id) => {
  await ProductDelAPI({ id })
  ElMessage.success('产品下架成功！')
  onListData()
}
/*
 *  表格： E
 */

/**
 *  分页 S
 */

// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onListData()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onListData()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped></style>
